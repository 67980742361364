/*
 * HTML5 Boilerplate
 *
 * What follows is the result of much research on cross-browser styling.
 * Credit left inline and big thanks to Nicolas Gallagher, Jonathan Neal,
 * Kroc Camen, and the H5BP dev community and team.
 *
 * Detailed information about this CSS: h5bp.com/css
 *
 * ==|== normalize ==========================================================
 */


/* =============================================================================
   HTML5 display definitions
   ========================================================================== */

article, aside, details, figcaption, figure, footer, header, hgroup, nav, section { display: block; }
audio, canvas, video { display: inline-block; *display: inline; *zoom: 1; }
audio:not([controls]) { display: none; }
[hidden] { display: none; }


/* =============================================================================
   Base
   ========================================================================== */

/*
 * 1. Correct text resizing oddly in IE6/7 when body font-size is set using em units
 * 2. Prevent iOS text size adjust on device orientation change, without disabling user zoom: h5bp.com/g
 */

html { font-size: 100%; -webkit-text-size-adjust: 100%; -ms-text-size-adjust: 100%; }

html, button, input, select, textarea { font-family: sans-serif; color: #222; }

body { margin: 0; font-size: 1em; line-height: 1.4; }

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection declarations have to be separate
 * Also: hot pink! (or customize the background color to match your design)
 */

::-moz-selection { background: #fe57a1; color: #fff; text-shadow: none; }
::selection { background: #fe57a1; color: #fff; text-shadow: none; }


/* =============================================================================
   Links
   ========================================================================== */

a { color: #00e; text-decoration: none; }
/*
a:visited { color: #551a8b; }
*/
a:hover { text-decoration: underline; }
a:focus { outline: thin dotted; }

/* Improve readability when focused and hovered in all browsers: h5bp.com/h */
a:hover, a:active { outline: 0; }


/* =============================================================================
   Typography
   ========================================================================== */

abbr[title] { border-bottom: 1px dotted; }

b, strong { font-weight: bold; }

blockquote { margin: 1em 40px; }

dfn { font-style: italic; }

hr { display: block; height: 1px; border: 0; border-top: 1px solid #ccc; margin: 1em 0; padding: 0; }

ins { background: #ff9; color: #000; text-decoration: none; }

mark { background: #ff0; color: #000; font-style: italic; font-weight: bold; }

/* Redeclare monospace font family: h5bp.com/j */
pre, code, kbd, samp { font-family: monospace, serif; _font-family: 'courier new', monospace; font-size: 1em; }

/* Improve readability of pre-formatted text in all browsers */
pre { white-space: pre; white-space: pre-wrap; word-wrap: break-word; }

q { quotes: none; }
q:before, q:after { content: ""; content: none; }

small { font-size: 85%; }

/* Position subscript and superscript content without affecting line-height: h5bp.com/k */
sub, sup { font-size: 75%; line-height: 0; position: relative; vertical-align: baseline; }
sup { top: -0.5em; }
sub { bottom: -0.25em; }


/* =============================================================================
   Lists
   ========================================================================== */

ul, ol { margin: 1em 0; padding: 0 0 0 40px; }
li { margin-bottom: 0.25em; }
dd { margin: 0 0 0 40px; }
nav ul, nav ol { list-style: none; list-style-image: none; margin: 0; padding: 0; }


/* =============================================================================
   Embedded content
   ========================================================================== */

/*
 * 1. Improve image quality when scaled in IE7: h5bp.com/d
 * 2. Remove the gap between images and borders on image containers: h5bp.com/i/440
 */

img { border: 0; -ms-interpolation-mode: bicubic; vertical-align: middle; }

/*
 * Correct overflow not hidden in IE9
 */

svg:not(:root) { overflow: hidden; }


/* =============================================================================
   Figures
   ========================================================================== */

figure { margin: 0; }


/* =============================================================================
   Forms
   ========================================================================== */

form { margin: 0; }
fieldset { border: 0; margin: 0; padding: 0; }

/* Indicate that 'label' will shift focus to the associated form element */
label { cursor: pointer; }

/*
 * 1. Correct color not inheriting in IE6/7/8/9
 * 2. Correct alignment displayed oddly in IE6/7
 */

legend { border: 0; *margin-left: -7px; padding: 0; white-space: normal; }

/*
 * 1. Correct font-size not inheriting in all browsers
 * 2. Remove margins in FF3/4 S5 Chrome
 * 3. Define consistent vertical alignment display in all browsers
 */

button, input, select, textarea { font-size: 100%; margin: 0; vertical-align: baseline; *vertical-align: middle; }

/*
 * 1. Define line-height as normal to match FF3/4 (set using !important in the UA stylesheet)
 */

button, input { line-height: normal; }

/*
 * 1. Display hand cursor for clickable form elements
 * 2. Allow styling of clickable form elements in iOS
 * 3. Correct inner spacing displayed oddly in IE7 (doesn't effect IE6)
 */

button, input[type="button"], input[type="reset"], input[type="submit"] { cursor: pointer; -webkit-appearance: button; *overflow: visible; }

/*
 * Re-set default cursor for disabled elements
 */

button[disabled], input[disabled] { cursor: default; }

/*
 * Consistent box sizing and appearance
 */

input[type="checkbox"], input[type="radio"] { box-sizing: border-box; padding: 0; *width: 13px; *height: 13px; }
input[type="search"] { -webkit-appearance: textfield; -moz-box-sizing: content-box; -webkit-box-sizing: content-box; box-sizing: content-box; }
input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-cancel-button { -webkit-appearance: none; }

/*
 * Remove inner padding and border in FF3/4: h5bp.com/l
 */

button::-moz-focus-inner, input::-moz-focus-inner { border: 0; padding: 0; }

/*
 * 1. Remove default vertical scrollbar in IE6/7/8/9
 * 2. Allow only vertical resizing
 */

textarea { overflow: auto; vertical-align: top; resize: vertical; }

/* Colors for form validity */
input:valid, textarea:valid {  }
input:invalid, textarea:invalid { background-color: #f0dddd; }


/* =============================================================================
   Tables
   ========================================================================== */

table { border-collapse: collapse; border-spacing: 0; }
td { vertical-align: top; }


/* ==|== primary styles =====================================================
   Author: T. Busser
   ========================================================================== */
@font-face
{
    font-family                 : 'SFShaiFontaiRegular';
    src                         : url('../fonts/SF_Shai_Fontai-webfont.eot');
    src                         : url('../fonts/SF_Shai_Fontai-webfont.eot?#iefix') format('embedded-opentype'),
                                  url('../fonts/SF_Shai_Fontai-webfont.woff') format('woff'),
                                  url('../fonts/SF_Shai_Fontai-webfont.ttf') format('truetype'),
                                  url('../fonts/SF_Shai_Fontai-webfont.svg#SFShaiFontaiRegular') format('svg');
    font-weight                 : normal;
    font-style                  : normal;
}

q {quotes: '“' '”' '‘' '’';} /* opening followed by closing quote pairs */
/* The equivalent using Unicode escapes:
  q {quotes: '\201C' '\201D' '\2018' '\2019';} */
q:before {content: open-quote;}
q:after {content: close-quote;}

body
{
	background-color	: #FFF;
	color				: #000;
	font				: normal 16px Verdana;
	line-height			: 18px;
	min-width           : 980px;
	overflow-y			: scroll;
}
	header
	{
		background-color	: #000;
		position			: relative;
	}
		.headerTitle
		{
			font-family			: SFShaiFontaiRegular, Verdana;
			font-size			: 52px;
			line-height			: 109px;
			margin				: 0;
			position			: relative;
			text-align			: center;
			z-index				: 10;
		}
		.headerImageLink
		{
			position			: absolute;
			z-index				: 20;
		}
			.headerLogo
			{
				height				: 100px;
				margin				: 5px;
				width				: 126px;
			}
		#headerRandomImage
		{
			bottom				: 1px;
			position			: absolute;
			right				: 0;
		}
		nav
		{
			background-color	: #EEE8AA;
			border-bottom		: 1px solid #000;
			clear				: both;
			height				: 50px;
		}
			.mainmenulist
			{
				margin              : 0 auto;
				width				: 980px;
			}
				.mainmenulistitem
				{
					float				: left;
					padding             : 0 5px;
					margin				: 0;
				}
					.mainmenuitem
					{
						color				: #8B0000;
						display             : block;
						font-size			: 14px;
						padding				: 16px 10px;
						text-align          : left;
					}
#body
{
	margin              : 0 auto;
	padding				: 0 20px;
	width				: 940px;
}
	#leftColumn
	{
		float				: left;
		padding             : 10px 10px 10px 0;
		width				: 180px;
	}
	#rightColumn
	{
		float				: left;
		padding             : 10px 0 10px 10px;
		width				: 740px;
	}

.lastElementMargin
{
	margin-bottom		: 1em;
}
footer
{
	border-top			: 1px solid #000;
	font-size			: 12px;
	margin				: 20px auto 5px;
	padding-top			: 5px;
	text-align			: center;
	width				: 980px;
}

.smallLogo
{
	height				: 117px;
	width				: 130px;
}
.chumIndexImage
{
	height				: 385px;
	width				: 400px;
}

/* ==
  Styles for the side bar
  == */
.sidebarmenulist
{
	list-style-type		: none;
	padding             : 0;
}
	.sidebarHeader
	{
		font-size 		    : 14px;
		margin-bottom		: 0.5em;
	}
	.sidebarLink
	{
		color				: #8B0000;
		display				: block;
		font-size           : 14px;
		margin-bottom		: 0.5em;
	}

/* ==
  Styles for the profile page
  == */
  .profileImage
  {
	height				: 188px;
	margin				: 0 0 0.5em 0.5em;
	width				: 250px;
 }


 /* ==
	Styles for the contact us page
	== */
#map
{
	height				: 200px;
	border				: 2px solid #000;
	width				: 450px;
}
.twoEqualColumns
{
	float				: left;
	margin				: 0 10px;
	width				: 450px;
}
.maplink
{
	font-size			: 12px;
}

/* ==
   Styles for the classes page
   == */
 .classesList
 {
	list-style-type		: none;
	padding				: 0;
 }
 .classesLabel
{
	display				: inline-block;
	width				: 100px;
}

/* ==
   Styles for the testimonials page
   == */
blockquote::before {
	display				: block;
	height				: 0;
	content				: "“";
	margin-left			: -.95em;
	font				: italic 400%/1 Cochin,Georgia,"Times New Roman", serif;
	color				: #999;
}
blockquote
{
	border-left			: 0.5em solid #DDD;
	-webkit-box-shadow	: 0 0 6px rgba(0,0,0,0.5);
	-moz-box-shadow		: 0 0 6px rgba(0,0,0,0.5);
	box-shadow			: 0 0 6px rgba(0,0,0,0.5);
	padding				: 10px;
}
	blockquote > p
	{
		margin-bottom		: 0.5em;
	}
	blockquote > footer
	{
		border				: 0;
		margin				: 0;
		text-align			: left;
	}


/* ==
   Styles for the gallery pages
   == */
.photo-gallery > a
{
	display: inline-block;
	padding: 0 5px 10px 0;
}
.photo-gallery > a > img
{
	border: 1px solid #515151;
}
/* ==|== non-semantic helper classes ========================================
   Please define your styles before this section.
   ========================================================================== */

/* For image replacement */
.ir { display: block; border: 0; text-indent: -999em; overflow: hidden; background-color: transparent; background-repeat: no-repeat; text-align: left; direction: ltr; *line-height: 0; }
.ir br { display: none; }

/* Hide from both screenreaders and browsers: h5bp.com/u */
.hidden { display: none !important; visibility: hidden; }

/* Hide only visually, but have it available for screenreaders: h5bp.com/v */
.visuallyhidden { border: 0; clip: rect(0 0 0 0); height: 1px; margin: -1px; overflow: hidden; padding: 0; position: absolute; width: 1px; }

/* Extends the .visuallyhidden class to allow the element to be focusable when navigated to via the keyboard: h5bp.com/p */
.visuallyhidden.focusable:active, .visuallyhidden.focusable:focus { clip: auto; height: auto; margin: 0; overflow: visible; position: static; width: auto; }

/* Hide visually and from screenreaders, but maintain layout */
.invisible { visibility: hidden; }

/* Contain floats: h5bp.com/q */
.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { *zoom: 1; }

.floatRight { float: right; }
.floatLeft { float: left; }


article
{
	margin				: 0 0 3em 0;
}

p
{
	margin				: 0.5em 0 1em;
}

h1
{
	font: 900 24px tahoma;
    color: red;
}

h2
{
	font: 900 20px tahoma;
    color: red;

}

h3
{
	font: 900 18px tahoma;
    color: red;
    margin: 2em 0 0 0;
}


div.logo
{
	position:absolute;
	float: left;
	height: 100px;
	width: 126px;
	margin: 0px;
	border: 0px;
	padding: 0px;
	z-index: 4;
}

div.imageright
{
	position:relative;
	float: right;
	height: 150px;
	width: 350px;
	margin: 0px;
	border: 0px;
	padding: 0px;
	z-index: 3;
}

.middle
{
    margin-left:auto;
    margin-right:auto;
	text-align: center;
}

.text-cta {
    font-size: 1.5em;
    font-weight: bold;
    margin-bottom: 2em;
    margin-top: 2em;
}
